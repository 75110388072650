define("ember-cli-notifications/components/notification-container", ["exports", "@ember/component", "@ember/object", "@ember/template", "@ember/service", "ember-cli-notifications/templates/components/notification-container"], function (_exports, _component, _object, _template, _service, _notificationContainer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let NotificationContainerComponent = (_dec = (0, _object.computed)('position'), _dec2 = (0, _object.computed)('zindex'), (_class = class NotificationContainerComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "notifications", _descriptor, this);

      _defineProperty(this, "tagName", '');

      _defineProperty(this, "layout", _notificationContainer.default);

      _defineProperty(this, "position", 'top');

      _defineProperty(this, "zindex", '1060');
    }

    get positionClass() {
      return `ember-cli-notifications-notification__container--${this.position}`;
    }

    get inlineStyle() {
      return (0, _template.htmlSafe)(`z-index: ${this.zindex};`);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "notifications", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "positionClass", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "positionClass"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "inlineStyle", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "inlineStyle"), _class.prototype)), _class));
  _exports.default = NotificationContainerComponent;
});